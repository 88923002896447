import { Avatar, Box, Flex } from '@chakra-ui/react'
import styled from '@emotion/styled'
import { motion } from 'framer-motion'

import { IncidentActionType } from '@/graphql/generated/schemas'
import { formatDateTime } from '@/utils/formatDateTime'

import { NonActionItem } from './Feed'

const StyledFeedItem = styled(motion.div)`
  padding-bottom: 10px;
  width: 100%;
  /* max-width: 460px; */
  &:last-of-type,
  &:first-of-type {
    padding-bottom: 0;
    .item-tail {
      display: none;
    }
    .item-arm {
      display: none;
    }
  }
`

export const itemStatusBgColor = (actionType: IncidentActionType | null) => {
  switch (actionType) {
    case null:
    case IncidentActionType.ReleaseByOperator:
      return '#fae8eb'
    case IncidentActionType.Resolved:
      return '#e8fae9'
    case IncidentActionType.AcknowledgementByOperator:
      return '#FFF0E5'
    default:
      return 'transparent'
  }
}

export const itemStatusIconBgGradientColor = (
  actionType: IncidentActionType | NonActionItem | null
) => {
  switch (actionType) {
    case null:
    case IncidentActionType.ReleaseByOperator:
    case IncidentActionType.SetRealToFalse:
      return 'linear(to-t, #D01030, #D01030)'
    case IncidentActionType.Cleared:
    case IncidentActionType.Resolved:
      return 'linear(to-t, #13B0B0, #46EF9F)'
    case NonActionItem.SystemAction:
      return 'linear(to-t, #ffffff, #ffffff)'
    default:
      return 'linear(to-t, brand.100, brand.200)'
  }
}

interface IProps {
  actionType?: IncidentActionType | null
  heading: string | React.ReactNode
  children?: React.ReactNode
  index?: number
  avatar?: string
  avatarName?: string
  createdAt?: string
  icon?: React.ReactNode
}

export const FeedItem = ({
  actionType,
  heading,
  avatar,
  avatarName,
  createdAt,
  index,
  icon,
  children,
}: IProps) => {
  return (
    <StyledFeedItem
      animate={{ opacity: 1, translateX: 0, translateY: 0 }}
      initial={{
        opacity: 0,
        translateX: 0,
        translateY: -50,
      }}
      transition={{ duration: 0.3, delay: index ? index * 0.1 : 0 }}
    >
      <Box
        bg={itemStatusBgColor(actionType)}
        borderRadius='lg'
        p='10px'
        pb='12px'
        position='relative'
      >
        <Box
          alignItems='center'
          bgGradient={itemStatusIconBgGradientColor(actionType)}
          borderRadius='base'
          d='flex'
          h='30px'
          justifyContent='center'
          position='absolute'
          w='30px'
          zIndex='2'
        >
          {icon}
        </Box>
        <Box
          borderLeft='2px dotted #E3E3E3'
          className='item-tail'
          h='100%'
          left='24px'
          position='absolute'
          top='40px'
        />
        <Box
          borderTop='2px dotted #E3E3E3'
          className='item-arm'
          left='40px'
          position='absolute'
          top='24px'
          w='17px'
        />
        <Box ml='50px' position='relative' top='2px' w='calc(100% - 50px)'>
          <Flex>
            {avatarName && (
              <Avatar
                mr='8px'
                mt='5px'
                name={avatarName}
                size='2xs'
                src={avatar}
              />
            )}
            <Box
              flexGrow={1}
              fontSize='16px'
              fontWeight='medium'
              letterSpacing='-0.53px'
            >
              {heading}
            </Box>
          </Flex>
          <Box
            color='#2D2E41'
            fontSize='12px'
            fontWeight='medium'
            letterSpacing='-0.33px'
            opacity='0.8'
          >
            {formatDateTime(createdAt)}
          </Box>
          <Box fontSize='16px' fontWeight='medium' letterSpacing='-0.53px'>
            {children}
          </Box>
        </Box>
      </Box>
    </StyledFeedItem>
  )
}
