import { MutationHookOptions, gql, useMutation } from '@apollo/client'

import { Notification } from '@/graphql/appsync/types'
import { Maybe, Scalars } from '@/graphql/generated/schemas'

export type UpdateNotificationInput = {
  notificationTimestamp: Scalars['String']
  read: Scalars['Boolean']
}

export type UpdateNotificationVariables = {
  input: UpdateNotificationInput
}

export type UpdateNotificationMutation = {
  __typename?: 'Mutation'
  updateNotification?: Maybe<Notification>
}

const UPDATE_NOTIFICATION = gql`
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      notificationTimestamp
      read
    }
  }
`

export const useUpdateNotification = (
  options?: MutationHookOptions<
    UpdateNotificationMutation,
    UpdateNotificationVariables
  >
) => {
  return useMutation(UPDATE_NOTIFICATION, {
    ...options,
    context: { target: 'appsync' },
  })
}
