import { Box } from '@chakra-ui/react'

import { getRoleDisplayName } from '@/features/managed-accounts'
import { Notification } from '@/graphql/appsync/types'

export const AudioNotificationTitle = ({
  notification,
}: {
  notification: Notification
}) => {
  return (
    <Box fontSize='16px' fontWeight='bold' noOfLines={2}>
      <Box as='span' color='#2E6CDB' data-testid='AudioNotificationTitle:title'>
        {`${getRoleDisplayName(notification.audioMessageUserRole)} ${
          notification.audioMessageUserName
        }`}
      </Box>{' '}
      sent a Voice Message to{' '}
      <Box
        as='span'
        color='#2E6CDB'
        data-testid='AudioNotificationTitle:details'
      >
        {notification.incidentDisplayId} {notification.incidentName}
      </Box>
    </Box>
  )
}
