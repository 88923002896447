import { HiOutlineDownload } from 'react-icons/hi'

import { Button, Tooltip } from '@chakra-ui/react'

export const DownloadReportButton = () => {
  return (
    <Tooltip
      arrow='#1b253f'
      bgColor='#1b253f'
      color='white'
      data-testid='incidentDrawer_body_downloadReportButton'
      hasArrow
      label='Download Incident Report'
      mt='5px'
      p='10px'
      shouldWrapChildren
    >
      <Button
        _focus={{ outline: 0 }}
        _hover={{ bgColor: '#1b253f' }}
        border='none'
        ms='5px'
        outline='none'
        variant='secondary'
      >
        <HiOutlineDownload />
      </Button>
    </Tooltip>
  )
}
