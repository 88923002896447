import { useState } from 'react'

import { useSetIsIncidentReal } from '../../hooks/useSetIsIncidentReal'
import { usePermissions } from '@/hooks/usePermissions'
import { useToasts } from '@/hooks/useToasts'
import { SubmitHandler, useForm } from 'react-hook-form'
import { useSelector } from 'react-redux'

import { Box, HStack, Text, useDisclosure } from '@chakra-ui/react'

import { Button, FormTextareaControl } from '@/components/ui'
import {
  FacilityFloorsDrawerDocument,
  FloorIncidentsDocument,
  useCreateIncidentActionMutation,
} from '@/graphql/generated/hooks'
import { IncidentActionType, IncidentStatus } from '@/graphql/generated/schemas'
import { selectMe } from '@/redux/me/meSlice'
import { BUTTON_PRESS, mixpanel } from '@/utils/analytics'

import { TakeOverIncidentModal } from '../..'
import { MixpanelDataIProps } from '../../types/types'
import { IncidentDataIProps } from './Resolve'
import { ResolveToggle } from './ResolveToggle'

export interface IProps {
  incident: IncidentDataIProps
  onActionComplete?: () => void
  mixpanelData?: MixpanelDataIProps
}

type FormValues = {
  resolutionDescription?: string
}

export const ResolveForm = ({
  incident,
  mixpanelData,
  onActionComplete,
}: IProps) => {
  const { shouldEnableIncidentActions } = usePermissions()
  const status = incident?.status
  const incidentId = incident?.id
  const ownerId = incident?.owner?.id

  const [createIncidentAction, { loading: isLoading }] =
    useCreateIncidentActionMutation()
  const [isFormDisabled, setIsFormDisabled] = useState(
    status === IncidentStatus.Active || status === IncidentStatus.Resolved
  )
  const [isReal, setIsReal] = useState(incident?.real)
  const { formState, handleSubmit, errors, register, watch } =
    useForm<FormValues>({
      defaultValues: { resolutionDescription: '' },
    })
  const description = watch('resolutionDescription')
  const { isSubmitting } = formState
  const me = useSelector(selectMe)
  const setIsIncidentReal = useSetIsIncidentReal()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { showSuccess, showError } = useToasts()

  const shouldTakeOverFirst = ownerId !== me?.id && ownerId !== undefined

  const onSubmit: SubmitHandler<FormValues> = async (values) => {
    mixpanel.track(`${BUTTON_PRESS} Resolve now - Submit`, mixpanelData)
    try {
      if (isReal !== incident?.real) {
        await setIsIncidentReal(incidentId, isReal, false, mixpanelData)
      }
      await createIncidentAction({
        variables: {
          input: {
            incidentId,
            type: IncidentActionType.Resolved,
            message: values.resolutionDescription,
          },
        },
        refetchQueries: [
          'Devices',
          {
            query: FloorIncidentsDocument,
            variables: { floorId: incident?.floor?.id },
          },
          {
            query: FacilityFloorsDrawerDocument,
            variables: { facilityId: incident?.facility?.id },
          },
        ],
      })

      onActionComplete?.()
      setIsFormDisabled(true)
      showSuccess('Incident is resolved.')
    } catch (e) {
      showError(e)
    }
  }

  const trackResolutionDescriptionInputFocus = () => {
    mixpanel.track(
      `'Resolution Description Input Field Focused (Resolve Tab)',`,
      mixpanelData
    )
  }

  const onCancel = () => {
    mixpanel.track(`${BUTTON_PRESS} Resolve - Cancel`, mixpanelData)
    onActionComplete?.()
  }

  const onToggleChange = (real: boolean) => {
    setIsReal(real)
  }

  return (
    <Box mb={8} mt={1} width='100%'>
      <form>
        <Box display='flex' flexDirection='column'>
          <Text fontSize='18px' fontWeight='bold'>
            Was this a real incident?
          </Text>
          <Text
            fontSize='13px'
            fontStyle='italic'
            marginBottom='20px'
            marginTop='2px'
          >
            (False Incidents will be tracked for Noise Reduction Features and
            removed from Program Analytics)
          </Text>
          <ResolveToggle
            incidentId={incidentId}
            isReal={isReal}
            mixpanelData={mixpanelData}
            onChange={onToggleChange}
          />
          <Box h='30px' />
          <FormTextareaControl
            data-testid='incidentDrawer_resolve_textarea'
            errorMessage={
              errors?.resolutionDescription &&
              errors?.resolutionDescription.message
            }
            id='resolutionDescription'
            isInvalid={!!errors?.resolutionDescription}
            isLabelBold={true}
            label='Incident Resolution Description'
            onFocus={trackResolutionDescriptionInputFocus}
            placeholder='Enter a description of how the Incident was resolved'
            textareaRef={register()}
          />
          <HStack alignSelf='flex-end' mt={6} spacing={3}>
            <Button
              data-testid='incidentDrawer_resolve_cancel'
              onClick={onCancel}
              variant='secondary'
            >
              Cancel
            </Button>
            <Button
              data-testid='incidentDrawer_resolve_submit'
              isDisabled={
                isFormDisabled ||
                description === '' ||
                (!shouldTakeOverFirst && !shouldEnableIncidentActions)
              }
              isLoading={isSubmitting}
              loadingText='Resolving...'
              onClick={shouldTakeOverFirst ? onOpen : handleSubmit(onSubmit)}
              variant='success'
            >
              {shouldTakeOverFirst ? 'Take over & Resolve now' : 'Resolve now'}
            </Button>
          </HStack>
        </Box>
      </form>
      {isOpen && (
        <TakeOverIncidentModal
          currentOwner={`${incident?.owner.firstName} ${incident?.owner.lastName}`}
          displayId={incident?.displayId}
          incidentName={incident?.name}
          isLoading={isLoading}
          isOpen={isOpen}
          nextAction={IncidentActionType.Resolved}
          onClose={onClose}
          onSubmit={handleSubmit(onSubmit)}
        />
      )}
    </Box>
  )
}
