import { IncidentType } from '@/graphql/generated/schemas'

export const getDescriptionMessage = (type: IncidentType) => {
  switch (type) {
    case IncidentType.DoorHeldOpen:
      return 'was held open.'
    case IncidentType.DoorHeldClose:
      return 'was held close.'
    case IncidentType.ForcedEntry:
      return 'was forced open.'
    case IncidentType.LineCrossing:
      return 'detected a boundary crossing.'
    case IncidentType.Tailgating:
      return 'Multiple people passed through a restricted entrance with only one authorized credential.'
    default:
      return ''
  }
}
