import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { Avatar, Box, Icon, Square, Tooltip } from '@chakra-ui/react'
import styled from '@emotion/styled'

import { selectSideNavOpen } from '@/redux/ui/uiSlice'
import { SIDE_BAR_WIDTH_CLOSED, SIDE_BAR_WIDTH_OPEN } from '@/styles/theme'

const StyledSideBarNavItem = styled.div<{
  isExpanded: boolean
  ['data-active']: boolean
}>`
  cursor: pointer;
  display: grid;
  grid-template-columns: max-content max-content;
  gap: 5px;
  width: ${(p) => (p.isExpanded ? SIDE_BAR_WIDTH_OPEN : SIDE_BAR_WIDTH_CLOSED)};
  align-items: center;
  background: ${(p) =>
    p['data-active']
      ? 'linear-gradient(158.67deg, #6096f5 100%, #3379c7 0%)'
      : 'transparent'};
  transition: ease-in-out 0.3s width;

  .chakra-icon {
    color: ${(p) => (p['data-active'] ? 'white' : '#5a6c9a')};
    transition: 0.3s;
  }

  &:hover {
    background-color: #1b253f;
  }
`

const StyledAvatar = styled(Avatar)`
  .chakra-avatar__initials {
    font-size: 15px;
    font-weight: 700;
    letter-spacing: -0.5px;
    line-height: 19px;
  }
`

interface IProps {
  label: string
  avatarName?: string
  subLabel?: string
  icon?: React.ElementType
  onClick?: () => void
  onMouseOver?: () => void
  path?: string
  dataTestId?: string
}

export const SideBarNavItem = ({
  label,
  avatarName,
  subLabel,
  icon,
  onClick,
  onMouseOver,
  path,
  dataTestId,
}: IProps) => {
  const { pathname, push } = useRouter()
  const isSideNavOpen = useSelector(selectSideNavOpen)
  const isActive = pathname.includes(path)

  const onPathChange = () => {
    if (path) {
      push(path)
    }
  }

  return (
    <StyledSideBarNavItem
      data-active={isActive}
      data-testid={dataTestId}
      isExpanded={isSideNavOpen}
      onClick={() => {
        onClick?.()
        onPathChange()
      }}
      onMouseOver={onMouseOver}
    >
      <Tooltip
        bgColor='#1b253f'
        bgGradient={
          isActive
            ? 'linear-gradient(158.67deg, #6096f5 100%, #3379c7 0%)'
            : 'transparent'
        }
        color='#fff'
        fontSize='16px'
        fontWeight='semibold'
        isDisabled={isSideNavOpen}
        label={label}
        letterSpacing='-0.65px'
        p='2'
        placement='right'
        rounded='5px'
      >
        <Square h='60px' w='60px'>
          {avatarName ? (
            <StyledAvatar
              bgGradient='linear-gradient(180deg, #50647A 0%, #3F609C 100%)'
              boxShadow='0 4px 8px 0 rgba(44,133,244,0.26)'
              color='#fff'
              fontSize='15px'
              h='42px'
              letterSpacing='-0.5px'
              name={avatarName}
              w='42px'
            />
          ) : (
            <Icon
              as={icon}
              boxSize='24px'
              strokeColor={isActive ? '#fff' : '#5a6c9a'}
            />
          )}
        </Square>
      </Tooltip>
      {isSideNavOpen && (
        <Box>
          {subLabel && (
            <Box
              color='#fff'
              fontSize='10px'
              letterSpacing='-0.4px'
              opacity={0.4}
            >
              {subLabel}
            </Box>
          )}

          {isSideNavOpen && (
            <Box
              color='#fff'
              fontSize='16px'
              fontWeight='medium'
              letterSpacing='-0.65px'
            >
              {label}
            </Box>
          )}
        </Box>
      )}
    </StyledSideBarNavItem>
  )
}
